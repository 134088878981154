import 'whatwg-fetch'; // apollo-link-http requires fetch
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-client';

const link = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
});

const cache = new InMemoryCache();

export default new ApolloClient({
  // @ts-expect-error
  link,
  cache,
});
