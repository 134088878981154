import gql from 'graphql-tag';

import { CurrentUser } from './__generated__/CurrentUser';

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      role
      firstName
      lastName
      email
      unconfirmedEmail
      twitterUsername
      website
      location
      projectName
      projectWebsite
      socialUsername
      socialUrl
      avatarUrl
      yearsAttended

      showInAttendeeDirectory
      slackInviteRequestedAt
      slackManualInviteRequested
      slackManualInviteSentAt
      slackInviteEmail

      currentSurvey {
        id
        aboutResponse1
        aboutResponse2
        aboutResponse3
        identifiesDiverseDetails
        partnerEmail
        partnerTwitter
        requiresSubsidizationDetails
      }

      currentEventInvite {
        id
        guest
        passType
        paymentRequired

        pass {
          id
        }
      }

      currentPass {
        id
        status
        shirtSize
        accessibilityRequirements
        childcareNeeds
        dietaryNeeds
      }
    }
  }
`;

export type { CurrentUser };
