import './ErrorBoundary.scss';

declare global {
  interface Window {
    Bugsnag?: { notify(error: Error): void };
  }
}

import * as React from 'react';

interface Props {
  children: React.ReactNode;
}

interface State {
  error: Error | null;
  shouldReload: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  static getDerivedStateFromError(error: Error): Partial<State> {
    if ('request' in error) {
      // JS or CSS chunk failed to load, reload the page
      return { shouldReload: true };
    } else {
      return { error };
    }
  }

  state = { error: null, shouldReload: false };

  componentDidCatch(error: Error) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error);
    }

    if (window.Bugsnag != null) {
      window.Bugsnag.notify(error);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.shouldReload && !prevState.shouldReload) {
      location.reload();
    }
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error != null) {
      return (
        <div className="ErrorBoundary">
          <p>An error has occurred.</p>

          <p>Please refresh the page and try again.</p>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
